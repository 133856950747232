import { BehaviorSubject, Observable, of } from 'rxjs';
import { take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ImgAnnotation } from '../model/img-annotation';
import { ImgDbService } from './img-db.service';
import { Action } from '../model/action';
import { ActionType } from '../model/action-type.enum';
import { v4 as uuidv4 } from 'uuid';
import { MessageData, MessageType } from '../../message/models/message';
import { MessageService } from '../../message/services/message.service';

@Injectable()
export class AnnotationStateService {
    public $annotations: BehaviorSubject<ImgAnnotation[]> = new BehaviorSubject(
        []
    );
    private actions: Action[] = [];
    private actionStateIndex: number = -1;
    private imageDbId: string;
    private maxActionsLimit = 50;
    private selectedImgId: string;
    private commands: Map<string, Action[]> = new Map<string, Action[]>();

    constructor(
        private imgDbService: ImgDbService,
        private messageService: MessageService
    ) {}

    fetchAnnotations(imageDbId: string, imgId: string) {
        this.selectedImgId = imgId;
        this.imageDbId = imageDbId;
        this.imgDbService
            .getAllAnnotationForImage(imageDbId, imgId)
            .subscribe((annotArray: ImgAnnotation[]) => {
                this.commands.clear();
                this.$annotations.next(annotArray);
                this.resetActions();
            });
    }

    hasUndo() {
        return (
            this.actions.length > 0 &&
            this.actionStateIndex !== this.actions.length - 1 &&
            this.actions[this.actionStateIndex + 1].annotation.id !== undefined
        );
    }

    hasRedo() {
        return (
            this.actions.length > 0 &&
            this.actionStateIndex >= 0 &&
            this.actions[this.actionStateIndex].annotation.id !== undefined
        );
    }

    undo() {
        const action = this.actions[++this.actionStateIndex];
        switch (action.type) {
            case ActionType.Add:
                this.removeAnnotation(action.annotation.id, action);
                break;
            case ActionType.Remove:
                this.addAnnotation(action.annotation, action);
                break;
        }
    }

    redo() {
        const action = this.actions[this.actionStateIndex--];
        switch (action.type) {
            case ActionType.Remove:
                this.removeAnnotation(action.annotation.id, action);
                break;
            case ActionType.Add:
                this.addAnnotation(action.annotation, action);
                break;
        }
    }

    resetActions() {
        this.actionStateIndex = -1;
        this.actions = [];
    }

    addAnnotation(newAnnotation: ImgAnnotation, action?: Action) {
        if (!newAnnotation.id) {
            newAnnotation.id = uuidv4();
        }
        const newAction: Action = {
            type: ActionType.Add,
            annotation: newAnnotation,
        };
        this.handleAction(newAction);
        this.$annotations.next([...this.$annotations.value, newAnnotation]);

        if (action) {
            // Set also action in this.action array by reference (IDK why)
            action.annotation = newAnnotation;
        } else {
            this.addAction(newAction);
        }
    }

    private handleAction(action: Action) {
        /**
         * Sets the specified Action in the Commands Map. Handles Case if action
         * with the same ImgAnnotation ID is already present in the Commands Map. Will not include Duplicates.
         * @param(action) The Action to add to the commands Map
         * @returns void, commands map is changed in memory
         */

        let fromMap = this.commands.get(action.annotation.id);
        if (!fromMap) {
            this.commands.set(action.annotation.id, [action]);
        } else {
            this.commands.get(action.annotation.id).push(action);
        }
    }

    removeAllAnnotationsForCategory(categoryId: string) {
        let newAnnotations = this.$annotations.value.filter(
            (annotation: ImgAnnotation) => {
                const toBeRemoved = annotation.categoryId === categoryId;
                if (toBeRemoved) {
                    const removeAction = {
                        type: ActionType.Remove,
                        annotation: annotation,
                    } as Action;
                    this.handleAction(removeAction);
                    this.addAction(removeAction);
                }
                return !toBeRemoved;
            }
        );
        this.$annotations.next(newAnnotations);
    }

    removeAnnotation(id: string, action?: Action) {
        const index = this.getAnnotationIndex(this.$annotations.getValue(), id);

        if (index === -1) {
            return;
        }
        const removed: ImgAnnotation = this.$annotations
            .getValue()
            .splice(index, 1)[0];

        this.handleAction({
            type: ActionType.Remove,
            annotation: removed,
        } as Action);

        if (action) {
            action.annotation = removed;
        } else {
            this.addAction({
                type: ActionType.Remove,
                annotation: removed,
            } as Action);
        }

        this.$annotations.next(this.$annotations.getValue());
    }

    selectAnnotation(id) {
        this.$annotations
            .pipe(take(1))
            .subscribe((annotations: ImgAnnotation[]) => {
                annotations.forEach(
                    (annotation: ImgAnnotation) =>
                        (annotation.active =
                            annotation.id === id ? !annotation.active : false)
                );
                this.$annotations.next(annotations);
            });
    }

    saveCommands(loadAfterSave: boolean, selectedImageId: string): void {
        if (this.commands.size > 0) {
            this.prepareAndSendCommands().subscribe(
                (imgAnnotations: ImgAnnotation[]) => {
                    if (
                        loadAfterSave &&
                        selectedImageId === this.selectedImgId
                    ) {
                        // prepare for synchronized save and load of annotations
                        this.mergeCommandsAndAnnotations(imgAnnotations);
                    }
                }
            );
        }
    }

    saveCommandsBeforeLogout(): Observable<ImgAnnotation[]> {
        if (this.commands.size > 0) {
            return this.prepareAndSendCommands();
        } else return of(null);
    }

    private prepareAndSendCommands(): Observable<ImgAnnotation[]> {
        this.cleanUpCommands();
        const clonedActions = [...this.commands.values()].flat().slice();
        this.commands.clear();
        return this.imgDbService.saveAnnotationCommands(
            this.imageDbId,
            this.selectedImgId,
            clonedActions
        );
    }

    private cleanUpCommands(): void {
        /**
         * Cleans Up unnecessary commands.
         * For Example [(AnnotationID: 1, Add), (AnnotationID:1, Remove)] is the same as not having
         * any commands for that action.
         * @returns void, commands map is changed in memory
         */
        this.commands.forEach((value, key) => {
            let resultAction: Action = undefined;
            value.forEach((action) => {
                resultAction = this.cleanUpAction(action, resultAction);
            });
            if (!resultAction) {
                this.commands.delete(key);
            } else {
                this.commands.set(key, [resultAction]);
            }
        });
    }

    private cleanUpAction(currentAction: Action, lastAction: Action): Action {
        /**
         * cleans Up Single Action
         * @param(currentAction) The currently Evaluated Action
         * @param(lastAction) The Action that happened before the currently evaluated action
         * @returns the currently resulting new Action
         */
        if (!lastAction) {
            return currentAction;
        } else {
            switch (lastAction.type) {
                case ActionType.Remove:
                    if (currentAction.type === ActionType.Add) {
                        return undefined;
                    }
                    return currentAction;

                case ActionType.Add:
                    if (currentAction.type === ActionType.Remove) {
                        return undefined;
                    }
                    return currentAction;
            }
        }
    }

    private mergeCommandsAndAnnotations(
        visualAnnotations: ImgAnnotation[]
    ): void {
        const updatedCommands: Map<string, Action[]> = new Map<
            string,
            Action[]
        >();
        this.commands.forEach((value, id) => {
            if (
                !this.updateVisualAnnotationsAndCheckIfCommandAlreadyExecuted(
                    visualAnnotations,
                    // Last Action is the action that counts for now
                    value[value.length - 1]
                )
            ) {
                // Wasn't previously saved in backend -> add it back to commands
                updatedCommands.set(id, [value[value.length - 1]]);
            }
        });
        this.commands = updatedCommands;

        // Do Toast Messages
        // Additions of Annotations from parallel Source
        let newFromParallelEditing = visualAnnotations.filter(
            (element: ImgAnnotation) => {
                return (
                    this.$annotations.value.find(
                        (annotation: ImgAnnotation) => {
                            return annotation.id === element.id;
                        }
                    ) === undefined
                );
            }
        );
        // Deletion of Annotation from parallel Source
        newFromParallelEditing = newFromParallelEditing.concat(
            this.$annotations.value.filter((element: ImgAnnotation) => {
                return (
                    visualAnnotations.find((annotation: ImgAnnotation) => {
                        return annotation.id === element.id;
                    }) === undefined
                );
            })
        );
        if (newFromParallelEditing.length > 0) {
            const messageData: MessageData = {
                type: MessageType.SUCCESS,
                message: {
                    text: 'Loaded Annotation Changes from Source',
                },
            };
            this.messageService.displayMessage(messageData);
        }
        this.$annotations.next(visualAnnotations);
    }

    private updateVisualAnnotationsAndCheckIfCommandAlreadyExecuted(
        visualAnnotations: ImgAnnotation[],
        actionToCheck: Action
    ): boolean {
        switch (actionToCheck.type) {
            case ActionType.Add:
                //This has to be changed if moving an BBox is possible in the future
                if (
                    visualAnnotations.find((annotation: ImgAnnotation) => {
                        return annotation.id === actionToCheck.annotation.id;
                    }) === undefined
                ) {
                    // create Annotation not yet saved in Backend, add it back to visual Annotations
                    visualAnnotations.push(actionToCheck.annotation);
                    return false;
                }
                return true;
            case ActionType.Remove:
                const elementIndex = visualAnnotations.findIndex(
                    (annotation: ImgAnnotation) => {
                        return annotation.id === actionToCheck.annotation.id;
                    }
                );
                if (elementIndex !== -1) {
                    //Annotation net yet deleted in Backend, remove it from visual Annotations
                    visualAnnotations.splice(elementIndex, 1);
                    return false;
                }
                return true;
        }
    }

    private addAction(action: Action) {
        this.actions.splice(this.actionStateIndex + 1, 0, action);

        // This needs to be changed if we care about correct undo/redo handling after 50 actions
        if (this.actions.length > this.maxActionsLimit) {
            this.actions.splice(this.maxActionsLimit);
        }
    }

    private getAnnotationIndex(annotations, id) {
        return annotations.findIndex((annotation) => annotation.id === id);
    }

    public changedAnnotationState(): boolean {
        return this.commands.size > 0;
    }
}

import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ImgCategory } from '../model/img-category';
import { DeleteCategoryDialogComponent } from './delete-category-dialog/delete-category-dialog.component';
import { ColorService } from '../../shared/services/color.service';
import { UserCacheService } from 'src/app/xtra/user-cache/user-cache-service';

export type RadioButtons = 'images' | 'labels';
export const COUNTING_GROUP_SELECTION_SAVE_LOCATION_PREFIX: string =
    'countingGroupOf';

@Component({
    selector: 'app-img-db-category-manager',
    templateUrl: './img-db-category-manager.component.html',
    styleUrls: ['./img-db-category-manager.component.scss'],
})
export class ImgDbCategoryManagerComponent implements OnInit {
    @Input() imageDatabaseId: string;
    @Input() categories: ImgCategory[];
    @Input() selectedCategoryNames: string[];
    @Input() numberOfUncategorizedImages: number;
    @Input() isEmptyCategorySelected: boolean;
    @Input() isDbOfTraining: boolean = false;

    @Output() categoryCreated = new EventEmitter<string>();
    @Output() categoryClicked = new EventEmitter<string>();
    @Output() categoryDeleted = new EventEmitter<number>();
    @Output() emptyCategorySelected = new EventEmitter<void>();
    @ViewChild('labelName') lblName: ElementRef;
    public categoryInput = new FormControl('');
    public isFocusOnCategoryInput = false;

    availableOptions: RadioButtons[] = ['labels', 'images'];
    selectedOption: RadioButtons = 'images';
    hoveredCategoryIndex: number | null = null;

    constructor(
        private dialog: MatDialog,
        public translate: TranslateService,
        private colorService: ColorService,
        private userCacheService: UserCacheService
    ) {}

    ngOnInit(): void {
        this.userCacheService
            .loadData(
                `${COUNTING_GROUP_SELECTION_SAVE_LOCATION_PREFIX}${this.imageDatabaseId}`
            )
            .subscribe((savedSelection: { selected: RadioButtons }) => {
                if (savedSelection) {
                    this.selectedOption = savedSelection.selected;
                }
            });
    }

    onEnterKeypressAddCategory(categoryName: string) {
        if (
            categoryName &&
            !ImgCategory.includes(this.categories, categoryName)
        ) {
            this.categoryCreated.emit(categoryName);
            this.resetForm();
        }
        this.lblName.nativeElement.focus();
    }

    resetForm() {
        this.categoryInput.reset();
        this.lblName.nativeElement.focus();
    }

    onDeleteCategoryClick(event: Event, category: ImgCategory) {
        if (category.id === undefined) {
            return;
        }
        const categoryIdToDelete: string = category.id;
        event.stopPropagation();
        this.openDeletionDialog(categoryIdToDelete)
            .afterClosed()
            .subscribe((confirmedCategoryIdToDelete: number) => {
                if (confirmedCategoryIdToDelete) {
                    this.categoryDeleted.emit(confirmedCategoryIdToDelete);
                }
            });
    }

    private openDeletionDialog(categoryToDelete: string) {
        return this.dialog.open(DeleteCategoryDialogComponent, {
            data: categoryToDelete,
            width: '650px',
            panelClass: 'delete-category-dialog', // can be used for global styling
        });
    }

    public onFocusHidePlaceholder() {
        this.isFocusOnCategoryInput = true;
    }

    public onFocusShowPlaceholder() {
        this.isFocusOnCategoryInput = false;
    }

    public getColor(category) {
        return this.colorService.getColor(category);
    }

    public onCountingGroupChange(group: RadioButtons) {
        this.userCacheService
            .saveData(
                `${COUNTING_GROUP_SELECTION_SAVE_LOCATION_PREFIX}${this.imageDatabaseId}`,
                { selected: group }
            )
            .subscribe();
    }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable, Subject } from 'rxjs';
import { ImgDb } from '../model/img-db';
import { Pageable, PagingResult } from 'src/app/shared/models/pageable';
import { ImgDbInfo } from '../model/img-db-info';
import { ImgWithSelection } from '../model/img-with-selection';
import { ImgCategory } from '../model/img-category';
import { ImgCategories } from '../model/img-categories';
import { ImgAnnotation } from '../model/img-annotation';
import { map } from 'rxjs/operators';
import { ContainerService } from '../../shared/services/container/container.service';
import {
    Container,
    ContainerCreateRequest,
    ContainerCreateResponse,
    ContainerPage,
    ContainerType,
} from '../../shared/services/container/container.types';
import { Action } from '../model/action';

@Injectable({
    providedIn: 'root',
})
export class ImgDbService {
    private readonly restUrl: string;
    readonly defaultPageNumber: number = 1;
    readonly defaultPageSize: number = 100;

    selection = new Subject<boolean>();

    constructor(
        private http: HttpClient,
        private containerService: ContainerService
    ) {
        this.restUrl = environment.backendUrl + '/rest/img-db';
    }

    getMyImageDbs(pageable?: Pageable): Observable<ContainerPage> {
        pageable = pageable || new Pageable();
        return this.containerService.getAllUserContainers(
            [ContainerType.IMAGE_DB],
            pageable
        );
    }

    getImageDb(id: string): Observable<Container> {
        return this.containerService.getById(id);
    }

    getImageDbByTrainingId(id: string): Observable<ImgDb> {
        const url = environment.backendUrl + `/rest/training/${id}/img-db`;
        return this.http.get<ImgDb>(url);
    }

    createImageDb(
        name: string,
        copyId: string
    ): Observable<ContainerCreateResponse> {
        const request: ContainerCreateRequest = {
            name: name,
            openly: false,
            containerType: ContainerType.IMAGE_DB,
            parameter: copyId,
        };
        return this.containerService.create(request);
    }

    updateName(containerId: string, name: string): Observable<ImgDb> {
        const containerRequest: ContainerCreateRequest =
            new ContainerCreateRequest();
        containerRequest.name = name;

        return this.containerService.update(containerId, containerRequest).pipe(
            map((container: Container) => {
                const imgDb = container.imageDb;
                imgDb.name = container.name;
                return imgDb;
            })
        );
    }

    getImageDbInfo(id: string) {
        const url = `${this.restUrl}/${id}/info`;
        return this.http.get<ImgDbInfo>(url);
    }

    getCategories(id: string) {
        const url: string = `${this.restUrl}/${id}/category`;
        return this.http.get<ImgCategories>(url);
    }

    createCategory(name: string, dbId: string): Observable<ImgCategory> {
        return this.http
            .post<ImgCategory>(`${this.restUrl}/${dbId}/category`, {
                name: name,
            })
            .pipe(
                map(
                    (category: ImgCategory) =>
                        new ImgCategory(category.id, category.name)
                )
            );
    }

    deleteCategory(categoryId: string, dbId: string) {
        return this.http
            .delete<void>(`${this.restUrl}/${dbId}/category/${categoryId}`)
            .subscribe();
    }

    getImages(
        selectedCategoryNames: string[],
        showNonSelectedImages: boolean,
        dbId: string,
        pageNumber: number = this.defaultPageNumber,
        pageSize: number = this.defaultPageSize
    ): Observable<PagingResult<ImgWithSelection>> {
        return this.http.get<PagingResult<ImgWithSelection>>(
            `${this.restUrl}/${dbId}/image`,
            {
                params: {
                    ps: pageSize,
                    pn: pageNumber - 1,
                    categories: selectedCategoryNames,
                    nonLabeledImages: showNonSelectedImages,
                },
            }
        );
    }

    getAllAnnotationForImage(
        dbId: string,
        imgId: string
    ): Observable<ImgAnnotation[]> {
        const url = `${this.restUrl}/${dbId}/image/${imgId}/annotation`;
        return this.http.get<ImgAnnotation[]>(url);
    }

    saveAnnotationCommands(
        dbId: string,
        imgId: string,
        actions: Action[]
    ): Observable<ImgAnnotation[]> {
        const url = `${this.restUrl}/${dbId}/image/${imgId}/commands`;
        return this.http.post<ImgAnnotation[]>(url, actions);
    }
}

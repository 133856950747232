<div class="dialog-wrapper">
    <div class="header">
        <img
            src="assets/svg/img-db/classificator-tree/deletion-modal/warning.svg"
            alt="warning-icon"
        />
        <p>{{ 'imgDb.classificatorTree.dialog.header' | translate }}</p>
    </div>
    <div mat-dialog-content class="question-text">
        <p>{{ 'imgDb.classificatorTree.dialog.question' | translate }}</p>
    </div>
    <div mat-dialog-actions class="action-buttons">
        <button [mat-dialog-close]="data" class="confirm-button">
            {{ 'imgDb.classificatorTree.dialog.confirmText' | translate }}
        </button>
        <button (click)="onCancel()" class="cancel-button">
            {{ 'imgDb.classificatorTree.dialog.cancelText' | translate }}
        </button>
    </div>
</div>

<div class="wrapper">
    <div class="header">
        <p>Labels</p>
    </div>
    <div class="label-name">
        <p>
            {{ 'imgDb.labelManager.input.label' | translate }}
        </p>
    </div>
    <div class="label-creation-wrapper">
        <div class="input-field-wrapper">
            <input
                #labelName
                id="label-name"
                type="text"
                class="input-field"
                [formControl]="categoryInput"
                (keyup.enter)="
                    onEnterKeypressAddCategory(categoryInput.value?.trim())
                "
                [placeholder]="
                    'imgDb.labelManager.input.placeholder' | translate
                "
            />
            <div
                class="clear-button"
                id="clear-input-field-button"
                *ngIf="categoryInput.value"
                (click)="resetForm()"
            >
                <span class="material-icons clear-icon">clear</span>
            </div>
        </div>
        <button
            id="add-new-label-button"
            class="add-button"
            (click)="onEnterKeypressAddCategory(categoryInput.value?.trim())"
        >
            <p>+</p>
        </button>
    </div>
    <div class="sorting-wrapper">
        <div class="radio-group">
            <label
                *ngFor="let option of availableOptions"
                [ngClass]="{ selected: selectedOption === option }"
                [id]="'radio-button-' + option"
                [title]="
                    'imgDb.labelManager.sortingRadioButtons.' +
                        option +
                        '.tooltip' | translate
                "
            >
                <input
                    type="radio"
                    name="option"
                    [(ngModel)]="selectedOption"
                    [value]="option"
                    (click)="onCountingGroupChange(option)"
                />
                <span class="custom-radio"></span>
                <p>
                    {{
                        'imgDb.labelManager.sortingRadioButtons.' +
                            option +
                            '.value' | translate
                    }}
                </p>
            </label>
        </div>
    </div>
    <ul
        class="list-group categories-list"
        *ngIf="categories?.length > 0"
        [ngClass]="{ 'from-training': isDbOfTraining }"
    >
        <li
            *ngFor="let category of categories; index as idx"
            class="list-group-item category-item"
            (click)="categoryClicked.emit(category.name)"
            [class.selected]="selectedCategoryNames.includes(category.name)"
            [style]="{
                borderColor:
                    selectedCategoryNames.includes(category.name) ||
                    hoveredCategoryIndex === idx
                        ? getColor(category.name)
                        : 'transparent'
            }"
            (mouseenter)="hoveredCategoryIndex = idx"
            (mouseleave)="hoveredCategoryIndex = null"
        >
            <div class="category-name">
                <span
                    class="dot"
                    [style]="{
                        backgroundColor: getColor(category.name)
                    }"
                >
                </span>
                <span class="category-name">{{ category.name }}</span>
            </div>
            <div class="info-labels">
                <div class="count-labels">
                    <span *ngIf="selectedOption === 'labels'">
                        {{ category.annotationCount }}
                    </span>
                    <span *ngIf="selectedOption === 'images'">
                        {{ category.imageCount }}
                    </span>
                </div>
                <div
                    class="trash-icon"
                    (click)="onDeleteCategoryClick($event, category)"
                >
                    <mat-icon class="icon">delete</mat-icon>
                </div>
            </div>
        </li>
    </ul>
    <ul
        class="list-group no-label-list"
        [ngClass]="{ 'not-alone': categories?.length > 0 }"
    >
        <li
            class="list-group-item"
            (click)="emptyCategorySelected.emit()"
            [class.selected]="isEmptyCategorySelected"
        >
            <div class="category-name">
                <span class="no-label">
                    {{ 'imgDb.labelManager.noLabel' | translate }}</span
                >
            </div>
            <div class="info-labels">
                <div class="count-labels">
                    <span *ngIf="selectedOption === 'labels'"> 0 </span>
                    <span *ngIf="selectedOption === 'images'">{{
                        numberOfUncategorizedImages
                    }}</span>
                </div>
            </div>
        </li>
    </ul>
</div>

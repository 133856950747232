import {
    ChangeDetectorRef,
    Component,
    Input,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { ImgDb } from './model/img-db';
import { ImgDbService } from './services/img-db.service';
import { ImgDbInfo } from './model/img-db-info';
import { ImgCategory } from './model/img-category';
import { ImgWithSelection } from './model/img-with-selection';
import { Observable, Subscription, tap } from 'rxjs';
import { ImgAnnotation } from './model/img-annotation';
import { ImgDbZoomService } from './services/img-db-zoom.service';
import { CategoryWithSelection } from './model/category-with-selection';
import { AnnotationStateService } from './services/annotation-state.service';
import { ImgLabelingViewerComponent } from './img-labeling-viewer/img-labeling-viewer.component';
import { TrainingDTO } from '../shared/models/training-dto';
import { TrainingStatus } from '../shared/models/training-status';
import { map, take } from 'rxjs/operators';
import { ImgDbUploadService } from './services/img-db-upload.service';
import { NgxFileDropEntry } from 'ngx-file-drop';
import { ImagesStorageService } from './services/images-storage.service';
import { PagingResult } from '../shared/models/pageable';
import { Container } from '../shared/services/container/container.types';
import { ImgDbUploadUtilService } from './services/img-db-upload-util.service';
import { ProjectSplitScreenService } from '../project-split-screen/services/project-split-screen.service';
import { SplitScreenState } from '../project-split-screen/components/project-split-screen/project-split-screen.component';

export enum SelectionButtonMode {
    Select = 'Select',
    Deselect = 'Deselect',
}

@Component({
    selector: 'app-img-db',
    templateUrl: './img-db.component.html',
    styleUrls: ['./img-db.component.scss'],
    providers: [ImagesStorageService],
})
export class ImgDbComponent implements OnInit, OnDestroy {
    protected readonly SplitScreenState = SplitScreenState;
    readonly MANAGE_DIALOG_PAGE_SIZE: number = 100;
    readonly LABELING_DIALOG_PAGE_SIZE: number = 8;

    navItems: string[] = ['overview', 'annotate'];

    hoveredNavItemIdx: number | null = null;
    hoveredFunctionalButtonIdx: number | null = null;

    activeViewIdx: number;
    imageDb: ImgDb;
    containerId: string;
    dbInfo: ImgDbInfo;
    categories: ImgCategory[] = [];
    selectedCategoryNames: string[] = [];
    selectedEditCategory: ImgCategory;
    isEmptyCategorySelected: boolean = false;
    uncategorizedImagesCount: number;
    annotations: ImgAnnotation[];

    dbName: string = '';
    trainingName: string;

    routingData = {
        name: '',
        path: '/workspace',
    };

    categoryTree = {};

    currentImagesInRow: number;
    lengthOfImagesList: number = 0;

    selectButtonMode: SelectionButtonMode = SelectionButtonMode.Select;
    selectButtonEnabled: boolean = true;

    isPanButtonActive: boolean = false;

    @ViewChild(ImgLabelingViewerComponent)
    labelComponent: ImgLabelingViewerComponent;

    @Input() training: TrainingDTO;
    isDbFromTraining: boolean = false;
    canEdit: boolean = true;
    imgDbId: string = '';
    currentPage: number = 1;

    subscriptions: Subscription[] = [];
    zoomSubscriptions: Subscription[] = [];
    splitScreenState: SplitScreenState;

    public constructor(
        private service: ImgDbService,
        private route: ActivatedRoute,
        private zoomService: ImgDbZoomService,
        private location: Location,
        private changeDetector: ChangeDetectorRef,
        public annotationStateService: AnnotationStateService,
        private uploadService: ImgDbUploadService,
        private uploadUtilService: ImgDbUploadUtilService,
        public imagesStorage: ImagesStorageService,
        private splitScreenStateService: ProjectSplitScreenService
    ) {}

    ngOnInit(): void {
        if (this.training) {
            this.isDbFromTraining = true;
            this.canEdit = this.training.status === TrainingStatus.NEW;
            this.subscriptions.push(
                this.splitScreenStateService.splitScreenStateChange$.subscribe(
                    (state: SplitScreenState) => {
                        this.splitScreenState = state;
                        this.setZoomLevel(state);
                    }
                )
            );
        } else {
            this.setZoomLevel();
        }
        this.populateDataAfterRetrieveImageDb();
        this.activeViewIdx = 0;
        this.annotationStateService.$annotations.next([]);

        this.subscriptions.push(
            this.uploadService.uploadProcessState.subscribe(() => {
                // TODO: call getImages() instead.
                this.populateDataAfterRetrieveImageDb();
            })
        );

        this.subscriptions.push(
            this.imagesStorage.selectedImage$.subscribe(
                (image: ImgWithSelection) => {
                    this.onImageSelectedForEditing(image);
                }
            )
        );
    }

    private populateDataAfterRetrieveImageDb() {
        this.retrieveImageDb()
            .pipe(
                tap((imageDb: ImgDb) =>
                    this.imagesStorage.setData(
                        this.MANAGE_DIALOG_PAGE_SIZE,
                        imageDb.id
                    )
                ),
                tap((db: ImgDb) => this.setImageDb(db)),
                tap((db: ImgDb) => this.getDbInfo(db)),
                tap((db: ImgDb) => this.getCategories(db)),
                tap((_) => this.getImages())
            )
            .subscribe((imgDb) => {
                this.imgDbId = imgDb.id;
                this.uploadService.setViewedImgDbId(this.imgDbId);
            });
    }

    retrieveImageDb(): Observable<ImgDb> {
        if (this.isDbFromTraining) {
            return this.service.getImageDbByTrainingId(this.training.uuid);
        } else {
            return this.service.getImageDb(this.extractDbIdFromUrl()).pipe(
                map((container: Container) => {
                    this.containerId = container.id;
                    const imgDb = container.imageDb;
                    imgDb.name = container.name;
                    return imgDb;
                })
            );
        }
    }

    setZoomLevel(state?: SplitScreenState) {
        this.zoomSubscriptions.forEach((subscription: Subscription) => {
            subscription.unsubscribe();
        });
        this.zoomSubscriptions = [];
        let imagesInRow: number;

        if (state === SplitScreenState.DEFAULT) {
            imagesInRow = 4;
        }
        if (state === SplitScreenState.LEFT_CLOSED) {
            imagesInRow = 6;
        }

        this.zoomService.setDefaultValue(imagesInRow);

        this.zoomSubscriptions.push(
            this.zoomService.imagesInRowManageDialog.subscribe((count) => {
                this.currentImagesInRow = count;
            })
        );

        this.zoomSubscriptions.push(
            this.zoomService.zoomLevelLabelDialog.subscribe((level) => {
                this.onZoomLevelChanged(level);
            })
        );
    }

    getImages() {
        this.imagesStorage
            .getImages()
            .subscribe((pageable: PagingResult<ImgWithSelection>) => {
                this.changeSelectButtonMode(SelectionButtonMode.Select);
                this.lengthOfImagesList = pageable.values.length;
                this.selectButtonEnabled = this.lengthOfImagesList > 0;
            });
    }

    extractDbIdFromUrl(): string {
        return this.route.snapshot.params['imgDbId'];
    }

    getBackButtonName() {
        let res = '';

        if (this.training) {
            res = this.training.name;
        } else if (this.imageDb) {
            res = this.imageDb.name;
        }

        return res;
    }

    onNavClick(idx: number): void {
        if (idx !== this.activeViewIdx) {
            this.activeViewIdx = idx;
            this.resetCurrentPage(true);
            this.zoomService.setCurrentZoomLevel(0);
        }
        this.changeSelectButtonMode(SelectionButtonMode.Select);
    }

    onNameUpdate(val: string): void {
        this.service.updateName(this.containerId, val).subscribe((imgDb) => {
            this.setImageDb(imgDb);
        });
    }

    onSelectEditCategory(category: ImgCategory): void {
        // selection of Category in Label/Annotation Editor
        this.isPanButtonActive = false;
        if (this.selectedEditCategory === category) {
            this.toggleImageDraggingInChildComponent(this.isPanButtonActive);
        }
        this.selectedEditCategory = category;
    }

    onImageSelectedForEditing(img: ImgWithSelection) {
        if (img !== null) {
            this.changeDetector.detectChanges();
            this.annotationStateService.fetchAnnotations(
                this.imageDb.id,
                img.id
            );
        }
    }

    handleCategorySelect(category: CategoryWithSelection) {
        category.active = !category.active;
        this.updateImgAnnotations(category);
    }

    handleCategoryDelete(id: number) {
        const deletedAnnotationIndex = this.annotations.findIndex(
            (annotation: ImgAnnotation) =>
                annotation.id.toString() === id.toString()
        );

        if (deletedAnnotationIndex >= 0) {
            this.annotations.splice(deletedAnnotationIndex, 1);
        }
    }

    updateImgAnnotations(category: CategoryWithSelection) {
        const selectedAnnotation = this.annotations.find(
            (annotation: ImgAnnotation) =>
                annotation.id.toString() === category.annotationId.toString()
        );
        if (selectedAnnotation) selectedAnnotation.active = category.active;
    }

    updateCategoryTree(annotation: ImgAnnotation) {
        const category = this.categories.find(
            (cat) => cat.id + '' === annotation.categoryId + ''
        );
        if (annotation.active) this.categoryTree[category.name].active = true;
        this.categoryTree[category.name].items.forEach((item) => {
            if (item.annotationId.toString() === annotation.id.toString())
                item.active = annotation.active;
        });
    }

    onZoomLevelChanged(level: number) {
        if (level === 0) {
            this.isPanButtonActive = false;

            if (this.labelComponent) {
                this.toggleImageDraggingInChildComponent(
                    this.isPanButtonActive
                );
            }
        }
        this.changeDetector.detectChanges();
    }

    private setImageDb(imgDb: ImgDb): void {
        this.imageDb = imgDb;
        this.routingData.name = imgDb.name;
    }

    back(): void {
        this.location.back();
    }

    isViewActive(idx: number): boolean {
        return idx === this.activeViewIdx;
    }

    isNameEditable(): boolean {
        return this.activeViewIdx === 0;
    }

    getA11yForName(): string {
        return 'Name of current Image Database';
    }

    onSelectAllButtonClick(isAllSelected: boolean) {
        this.service.selection.next(isAllSelected);
        if (isAllSelected) {
            this.changeSelectButtonMode(SelectionButtonMode.Deselect);
        } else {
            this.changeSelectButtonMode(SelectionButtonMode.Select);
        }
    }

    changeSelectButtonMode(mode: SelectionButtonMode) {
        this.selectButtonMode = mode;
    }

    getDbInfo(db: ImgDb) {
        this.service
            .getImageDbInfo(db.id)
            .subscribe((info) => (this.dbInfo = info));
    }

    public createAndPersistCategory(categoryName: string) {
        let category: ImgCategory = new ImgCategory(undefined, categoryName, 0);
        this.categories.push(category);
        this.dbInfo.categoryCount++;
        this.service
            .createCategory(categoryName, this.imageDb.id)
            .subscribe((createdCategory) => {
                category.id = createdCategory.id;
            });
    }

    public selectOrUnselectCategory(clickedCategoryName: string) {
        this.modifySelectedCategoryNames(clickedCategoryName);
        this.resetCurrentPage(true);
    }

    private modifySelectedCategoryNames(clickedCategoryName: string) {
        if (!this.selectedCategoryNames.includes(clickedCategoryName)) {
            this.selectedCategoryNames.push(clickedCategoryName);
        } else {
            this.removeClickedCategoryAsSelected(clickedCategoryName);
        }
        this.imagesStorage.selectedCategoryNames = this.selectedCategoryNames;
    }

    private removeClickedCategoryAsSelected(clickedCategory: string) {
        this.selectedCategoryNames = this.selectedCategoryNames.filter(
            (category) => category !== clickedCategory
        );
    }

    public deleteCategoryAndPersist(categoryIdToDelete: string) {
        let cat: ImgCategory;
        this.categories = this.categories.filter((category) => {
            if (category.id === categoryIdToDelete) {
                cat = category;
                return false;
            }
            return true;
        });

        if (this.selectedCategoryNames.includes(cat.name)) {
            this.selectOrUnselectCategory(cat.name);
        }

        this.dbInfo.categoryCount--;
        this.service.deleteCategory(categoryIdToDelete, this.imageDb.id);
    }

    private getCategories(db: ImgDb) {
        this.service.getCategories(db.id).subscribe((response) => {
            this.categories = response.categories;
            this.uncategorizedImagesCount = response.uncategorizedImagesCount;
        });
    }

    public handleEmptyCategorySelected() {
        this.isEmptyCategorySelected = !this.isEmptyCategorySelected;
        this.imagesStorage.isEmptyCategorySelected =
            this.isEmptyCategorySelected;
        this.resetCurrentPage(true);
    }

    isSelectedImageBookmarked(): boolean {
        return !!(
            this.imagesStorage.selectedImage$.value &&
            this.imagesStorage.selectedImage$.value.isBookmarked
        );
    }

    toggleBookmark() {
        this.imagesStorage.toggleBookmark();
    }

    undo() {
        this.annotationStateService.undo();
    }

    redo() {
        this.annotationStateService.redo();
    }

    /**
     * Method receives one of three string values (see below) from html file and calls service
     * @param zoomAction has one of three values: 'in', 'out', 'default'
     * 'in' -> zoom in, each time one image in a row less
     * 'out' -> zoom out, each time one image in a row more
     * 'default' -> set to default view which is 6 images in a row
     */
    handleZoom(zoomAction: string) {
        if (this.lengthOfImagesList !== 0) {
            this.zoomService.handleZoom(zoomAction, this.activeViewIdx);
        }
    }

    get currentZoomLevel() {
        return this.zoomService.getCurrentZoomLevel(this.activeViewIdx);
    }

    get currentMaxZoom() {
        return this.zoomService.getCurrentMax(this.activeViewIdx);
    }

    get currentMinZoom() {
        return this.zoomService.getCurrentMin(this.activeViewIdx);
    }

    get currentDefaultZoom() {
        return this.zoomService.getCurrentDefault(this.activeViewIdx);
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((sub) => sub.unsubscribe());
        this.zoomSubscriptions.forEach((sub) => sub.unsubscribe());
        this.uploadService.setViewedImgDbId('');
        this.uploadService.cancelRefreshImages();
    }

    onPanButtonClick() {
        this.isPanButtonActive = !this.isPanButtonActive;
        this.toggleImageDraggingInChildComponent(this.isPanButtonActive);
    }

    private toggleImageDraggingInChildComponent(enable: boolean) {
        this.labelComponent.toggleImageDragging(enable);
    }

    onItemDropped(event: NgxFileDropEntry[]) {
        this.imagesStorage.selectedCategoryNames = this.selectedCategoryNames =
            [];

        this.imagesStorage.isEmptyCategorySelected =
            this.isEmptyCategorySelected = false;

        //TODO: resetCurrentPage true when subscribe on uploadProcessState removed?
        this.resetCurrentPage(false);
        this.uploadService.addToUploadQueue(this.imgDbId, event);
    }

    getValidImageFormats(): string[] {
        return this.uploadUtilService.VALID_FILE_FORMATS;
    }

    /**
     *  Will split the key by '.' and take the last element and add '-button' to the result.
     * @param navItemTranslationKey is a translation key for each nav element (imgDb.navItem.Manage)
     */
    getUniqueNavItemId(navItemTranslationKey: string) {
        const parts = navItemTranslationKey.split('.');
        return parts[parts.length - 1] + '-button';
    }

    resetCurrentPage(retrieveAllImages: boolean) {
        this.imagesStorage.changePage((this.currentPage = 1));
        if (retrieveAllImages) {
            this.imagesStorage
                .getImages()
                .pipe(take(1))
                .subscribe(() => {
                    this.imagesStorage.updateImageSelection(0);
                });
        }
    }
}

import { Component, Inject } from '@angular/core';
import {
    MAT_DIALOG_DATA,
    MatDialogConfig,
    MatDialogRef,
} from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-delete-annotations-dialog',
    templateUrl: './delete-annotations-dialog.component.html',
})
export class DeleteAnnotationsDialogComponent {
    constructor(
        public dialogRef: MatDialogRef<DeleteAnnotationsDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: MatDialogConfig<string>,
        public translate: TranslateService
    ) {}

    onCancel(): void {
        this.dialogRef.close();
    }
}
